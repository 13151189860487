import React from "react";
import LineaNaranja from "../LineaNaranja";
import "./DondeEncontrarnos.css";

const DondeEncontrarnos = () => {
  return (
    <div className="container">
      <div id="NovedadesHome" className=" padding-titulo ">
        <div className="container">
          <LineaNaranja />
          <h2 className="color-blanco mt-5 peso-bold d-none d-xl-block">
            DONDE ENCONTRARNOS
          </h2>
          <h3 className="color-blanco mt-5 peso-bold  d-xl-none d-block ">
            DONDE ENCONTRARNOS
          </h3>
        </div>
        <section className="d-flex justify-content-between flex-wrap">
          <div className="mt-4">
            <h5 className="pb-4 text-center">
              España - Calle Clavel 8, 2do A Murcia, 30100
            </h5>
            <article>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.5943194949086!2d-1.152115!3d38.009922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd638154f7a1a80b%3A0x7affeb318d026e57!2sDKZ%20Studio!5e0!3m2!1ses-419!2sar!4v1715727129700!5m2!1ses-419!2sar"
                className="mapa-width "
                height="450"
                style={{ border: "0px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="map spain"
              ></iframe>
            </article>
          </div>
          <div className="mt-4">
            <h5 className="pb-4  text-center">
              Argentina - Juramento 60 - SM de Tucumán (4000)
            </h5>
            <article>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.7122715324977!2d-65.19516572366281!3d-26.817288789225294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c2043517b2f%3A0xbc31928cde581c2d!2sGEB%2C%20Juramento%2060%2C%20T4001%20San%20Miguel%20de%20Tucum%C3%A1n%2C%20Provincia%20de%20Tucum%C3%A1n!5e0!3m2!1ses-419!2sar!4v1710719155804!5m2!1ses-419!2sar"
                className="mapa-width "
                height="450"
                style={{ border: "0px" }}
                allowfullscreen=""
                loading="lazy"
                title="map arg"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DondeEncontrarnos;
