import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useParams } from "react-router";
import { Button, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo:
    "DKZ studio se posiciona como líder en Marketing Digital en España en solo 9 meses.",
  subtitulo:
    "En un mundo donde la presencia en línea es fundamental para el éxito empresarial, DKZ studio continúa destacando con su enfoque innovador y su dedicación al marketing digital. Con la llegada del 2024, DKZ reafirma su posición como pionero en el campo.",
  descripcion:
    "“El año 2024 está marcando un hito significativo ya que se ha experimentado una expansión sin precedentes en España”, sostuvo Guillermo Sojo, actual CEO de la agencia. En solo 9 meses desde su llegada al continente europeo, la compañía ha logrado un impresionante posicionamiento gracias a un liderazgo visionario y colectivo por parte de varios miembros, colaboradores e integrantes.",
  descripcion1b:
    "“Estamos encantados de haber alcanzado un éxito tan notable en tan poco tiempo”, comentó Guillermo Sojo Bollini. “Nuestro enfoque en ofrecer soluciones efectivas dentro del área del marketing digital de vanguardia y nuestro compromiso con la excelencia han sido fundamentales para este logro”",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "Parte integral de esta expansión ha sido el enfoque de DKZ Studio en el área de redes sociales. Reconociendo el poder y la influencia de plataformas como Instagram, Facebook y Tiktok, DKZ  ha fortalecido su departamento de social media, destacando su gran potencial y profesionalismo en la creación y ejecución de estrategias efectivas.",
  descripcion2:
    " “Las redes sociales son un elemento crucial en la estrategia de marketing digital de cualquier empresa en la actualidad”, explicó Ignacio Pintos, director del departamento de social media de DKZ. “Nos enorgullece ofrecer a nuestros clientes campañas creativas y dinámicas que generan resultados tangibles”",
  descripcion2b:
    "Con su enfoque en la innovación, la creatividad y la excelencia en el servicio al cliente, DKZ studio continúa consolidando su posición como líder en España. Con la expansión a otros países, como Italia, Portugal e Inglaterra, como testimonio de su éxito, la compañía espera seguir superando expectativas y estableciendo nuevos estándares en el mercado global.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg",
};

export default function Novedades7DetCel() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  return (
    <div className="mt-0 container">
      <div className="mx-3 pb-5">
        <h3 className="text-white peso-bold">{titulo}</h3>
        <h5 className="text-white mt-4 ">{subtitulo}</h5>

        <br />
        <Image className="w-100" src={imagen1} alt="" fluid />
        <br />
        <h5 className="text-white mt-1 ms-3">{textoImagen1}</h5>

        <h6 className="text-white my-4 py-2">{fecha} </h6>

        <h5 className="text-white  mt-4 wrap-pre">
          {" "}
          {descripcion} <br />
          {descripcion1b} <br />
          {descripcion1c} <br />
          {descripcion1d}
        </h5>
        {tituloIntermedio === "" ? (
          ""
        ) : (
          <h5 className="text-white  mx-4 my-5 peso-bold">
            {tituloIntermedio}
          </h5>
        )}

        <h5 className="text-white mt-4 wrap-pre">
          {descripcion2} <br />
          {descripcion2b}
        </h5>
      </div>

      <div className="d-flex justify-content-center pt-4">
        <Link className="text-decoration-none text-white" to="/novedades/todas">
          <Button
            as={Link}
            to="/novedades/todas"
            className="boton-naranja-grande"
          >
            <h5 className="peso-bold ps-4 d-lg-none d-block">MÁS NOVEDADES</h5>
            <h3 className="peso-bold pe-3 d-lg-none d-block">➝</h3>
          </Button>
        </Link>
      </div>
    </div>
  );
}
