import React from "react";
import "../clientes/clientes.css";
import LineaNaranja from "../../LineaNaranja";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Image } from "react-bootstrap";

import fortaleza from "../../../assets/logos/fortaleza.png";
import santa from "../../../assets/logos/santaAna.png";
import hola from "../../../assets/logos/Hola.png";
import dkv from "../../../assets/logos/DKV.png";

import abanco250 from "../../../assets/logos/abancos250.png";
import cartagena250 from "../../../assets/logos/cartagena250.png";
import wereal250 from "../../../assets/logos/wereal250.png";
import polo250 from "../../../assets/logos/polo250.png";
import roma250 from "../../../assets/logos/roma250.png";

import dilema250 from "../../../assets/logos/dilema250.png";
import mondelez250 from "../../../assets/logos/mondelez250.png";
import cp250 from "../../../assets/logos/cajapopular250.png";

export default function Clientes() {
  return (
    <div>
      <div className="container padding-titulo">
        <div>
          <LineaNaranja />
        </div>
        <div>
          <h2 className="peso-bold pb-5">CLIENTES</h2>
        </div>
      </div>
      <>
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper "
        >
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3 icono-carousel-clientes"
                src={fortaleza}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={santa}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={hola}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={dkv}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3 icono-carousel-clientes"
                src={abanco250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={cartagena250}
                alt="Aumenta tus ventas"
                fluid
              />

              <Image
                className="py-3  icono-carousel-clientes"
                src={polo250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={roma250}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3 icono-carousel-clientes mx-5"
                src={dilema250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={wereal250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes mx-5"
                src={mondelez250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={cp250}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
}
