import axios from "axios";
import Papa from "papaparse";
import LineaNaranja from "../LineaNaranja";
import "./NovedadesHomeNew.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow } from "swiper";
import { Navigation } from "swiper";

import { NavLink } from "react-router-dom";

export default function NovedadesHomeNew() {
  return (
    <div className="pb-5">
      {/* ESCRITTORIO */}
      <div id="NovedadesHome" className=" padding-titulo d-none d-xl-block">
        <div className="container">
          <LineaNaranja />
          <h2 className="color-blanco mt-5 peso-bold">NOVEDADES</h2>
          <div className="d-flex flex-wrap justify-content-between pt-5 cards-novedades">
            <div>
              <NavLink
                to={"/noticia/DKZ-studio-brillo-en-el-fortaleza-sound-2024"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO BRILLO EN EL FORTALEZA SOUND 2024
                  </h6>
                </div>
              </NavLink>
            </div>
            <div>
              <NavLink
                to={"/noticia/DKZ-studio-inaugura-el-ciclo-de-entrevistas"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ studio Inaugura el Ciclo de Entrevistas “Persiguiendo
                    tus Sueños” con Gaston Ugarte de Pixar
                  </h6>
                </div>
              </NavLink>
            </div>
            <div>
              <NavLink
                to={
                  "/noticia/DKZ-studio-se-posiciona-como-líder-en-Marketing-Digital-en-España-en-solo-9-meses"
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO SE POSICIONA COMO LÍDER EN MARKETING DIGITAL EN
                    ESPAÑA EN SOLO 9 MESES
                  </h6>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* CELULAR */}
      <div id="NovedadesHome" className="padding-titulo d-xl-none d-block ">
        <div className="container">
          <div className="mx-3">
            <LineaNaranja />
            <h3 className="color-blanco mt-4 peso-bold">NOVEDADES</h3>
          </div>
        </div>
        <div className="pt-5 pb-0">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={1}
            navigation={false}
            control={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              strech: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Navigation]}
            spaceBetween={-20} // o margin en cada elemento
            className="swiper-novedades"
          >
            <SwiperSlide>
              <NavLink
                to={"/noticia/DKZ-studio-brillo-en-el-fortaleza-sound-2024"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO BRILLO EN EL FORTALEZA SOUND 2024
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink
                to={"/noticia/DKZ-studio-inaugura-el-ciclo-de-entrevistas"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ studio Inaugura el Ciclo de Entrevistas “Persiguiendo
                    tus Sueños” con Gaston Ugarte de Pixar
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink
                to={
                  "/noticia/DKZ-studio-se-posiciona-como-líder-en-Marketing-Digital-en-España-en-solo-9-meses"
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO SE POSICIONA COMO LÍDER EN MARKETING DIGITAL EN
                    ESPAÑA EN SOLO 9 MESES
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
