import axios from "axios";
import Papa from "papaparse";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow } from "swiper";
import { useEffect } from "react";
import { useState } from "react";
import { Navigation } from "swiper";

import { NavLink } from "react-router-dom";
import LineaNaranja from "../../../esp/components/LineaNaranja";

export default function NovedadesHomeEN() {
  const [trabajos, setTrabajos] = useState([]);

  useEffect(() => {
    const getTrabajos = async () => {
      const response = await axios.get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vQA6RTHRyOTuR1qZesdUWlzihrNNeINy7sgWYCrDeH-vfC_K5eUsWoc66RTDtfx6AEATMBVPYafw003/pub?gid=574932737&single=true&output=csv"
      );
      const imagenes = Papa.parse(response.data, { header: true });
      setTrabajos(imagenes.data);
    };
    getTrabajos();
  }, []);
  return (
    <div className="pb-5">
      {/* ESCRITTORIO */}
      <div id="NovedadesHome" className=" padding-titulo d-none d-xl-block">
        <div className="container">
          <LineaNaranja />
          <h2 className="color-blanco mt-5 peso-bold">NEWS</h2>
          <div className="d-flex flex-wrap justify-content-between pt-5 cards-novedades">
            <div>
              <NavLink
                to={"/news/DKZ-studio-brightness-in-the-fortress-sound-2024"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO SHINE AT FORTALEZA SOUND 2024
                  </h6>
                </div>
              </NavLink>
            </div>
            <div>
              <NavLink
                to={
                  "/news/DKZ-studio-Inaugurates-Chasing-Your-Dreams-Interview-Series-with-Pixar's-Gaston-Ugarte"
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ studio Inaugurates “Chasing Your Dreams” Interview
                    Series with Pixar's Gaston Ugarte
                  </h6>
                </div>
              </NavLink>
            </div>
            <div>
              <NavLink
                to={
                  "/news/DKZ-studio-is-positioned-as-a-leader-in-Digital-Marketing-in-Spain-in-just-9-months."
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg")`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO IS POSITIONED AS A LEADER IN DIGITAL MARKETING IN
                    SPAIN IN ONLY 9 MONTHS.
                  </h6>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* CELULAR */}
      <div id="NovedadesHome" className="padding-titulo d-xl-none d-block ">
        <div className="container">
          <div className="mx-3">
            <LineaNaranja />
            <h3 className="color-blanco mt-4 peso-bold">NEWS</h3>
          </div>
        </div>
        <div className="pt-5 pb-0">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={1}
            navigation={false}
            control={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              strech: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Navigation]}
            spaceBetween={-20} // o margin en cada elemento
            className="swiper-novedades"
          >
            <SwiperSlide>
              <NavLink
                to={"/news/DKZ-studio-brightness-in-the-fortress-sound-2024"}
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ STUDIO SHINE AT FORTALEZA SOUND 2024
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink
                to={
                  "/news/DKZ-studio-Inaugurates-Chasing-Your-Dreams-Interview-Series-with-Pixar's-Gaston-Ugarte"
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 py-3">
                    DKZ studio Inaugurates “Chasing Your Dreams” Interview
                    Series with Pixar's Gaston Ugarte
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink
                to={
                  "/news/DKZ-studio-is-positioned-as-a-leader-in-Digital-Marketing-in-Spain-in-just-9-months."
                }
                className=""
              >
                <div
                  className="fondo-novedades-home"
                  style={{
                    backgroundImage: `url(https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg)`,
                  }}
                >
                  <h6 className="peso-bold novedades-home-texto px-4 pt-3">
                    DKZ STUDIO IS POSITIONED AS A LEADER IN DIGITAL MARKETING IN
                    SPAIN IN ONLY 9 MONTHS.
                  </h6>
                </div>
              </NavLink>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
