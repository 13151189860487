import React from "react";
import "../clientesEN/clientes.css";
import LineaNaranja from "../../../esp/components/LineaNaranja";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Image } from "react-bootstrap";

import fortaleza from "../../../esp/assets/logos/fortaleza.png";
import santa from "../../../esp/assets/logos/santaAna.png";
import hola from "../../../esp/assets/logos/Hola.png";
import dkv from "../../../esp/assets/logos/DKV.png";
import wereal250 from "../../../esp/assets/logos/wereal250.png";

import abanco250 from "../../../esp/assets/logos/abancos250.png";
import cartagena250 from "../../../esp/assets/logos/cartagena250.png";
import polo250 from "../../../esp/assets/logos/polo250.png";
import roma250 from "../../../esp/assets/logos/roma250.png";

import dilema250 from "../../../esp/assets/logos/dilema250.png";
import mondelez250 from "../../../esp/assets/logos/mondelez250.png";
import cp250 from "../../../esp/assets/logos/cajapopular250.png";
import sancor250 from "../../../esp/assets/logos/sancor250.png";

export default function ClientesEN() {
  return (
    <div>
      <div className="container padding-titulo">
        <div>
          <LineaNaranja />
        </div>
        <div>
          <h2 className="peso-bold pb-5">CUSTOMERS</h2>
        </div>
      </div>
      <div className=" d-lg-block d-none ">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper "
        >
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3  icono-carousel-clientes"
                src={fortaleza}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={santa}
                alt="Aumenta tus ventas"
                fluid
              />

              <Image
                className="py-3  icono-carousel-clientes"
                src={hola}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={dkv}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3  icono-carousel-clientes"
                src={abanco250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={cartagena250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={polo250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={roma250}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-white">
            <div className="d-flex justify-content-center">
              <Image
                className="py-3 icono-carousel-clientes mx-5"
                src={dilema250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={wereal250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes mx-5"
                src={mondelez250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3 icono-carousel-clientes"
                src={cp250}
                alt="Aumenta tus ventas"
                fluid
              />
              <Image
                className="py-3  icono-carousel-clientes"
                src={sancor250}
                alt="Aumenta tus ventas"
                fluid
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* celular */}
      <div className="d-block d-lg-none">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper bg-white"
        >
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={fortaleza}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={santa}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={hola}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={dkv}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={wereal250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={abanco250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={cartagena250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>

          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={polo250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5 icono-carousel-clientes py-4"
              src={roma250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5 icono-carousel-clientes py-4"
              src={dilema250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>

          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={mondelez250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>

          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5  icono-carousel-clientes py-4"
              src={cp250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide className="cartas-slider">
            <Image
              className="mx-5 icono-carousel-clientes py-4"
              src={sancor250}
              alt="caja popular"
              fluid
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
