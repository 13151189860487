// import { NovedadesDetallados } from "../components/Novedades/NovedadesContenido/NovedadesDetallados";

import { Helmet } from "react-helmet";
import DosBotonesHomeEN from "../../components/dosBotonesHome/DosBotonesHomeEN";
import { FooterReactEN } from "../../components/footerReactEN/FooterReactEN";
import { NavbarReactV2EN } from "../../components/navbarReactV2EN/NavbarReactV2EN";
import Novedades9DetallesCelEN from "../../components/novedadesEN/novedadesDetalles/Novedades9DetallesCelEN";

import Novedades9DetallesEscEN from "../../components/novedadesEN/novedadesDetalles/Novedades9DetallesEscEN";

export default function Noticias9EN() {
  return (
    <div className="color-negro-fondo">
      <Helmet>
        <title>DKZ studio Brillo at Fortaleza Sound 2024</title>
        <meta
          property="og:title"
          content="DKZ studio Brillo at Fortaleza Sound 2024"
        />
        <meta
          name="description"
          content="DKZ stood out in the prestigious musical event with a first class audiovisual production, capturing every detail of the live performances of renowned Spanish artists."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg"
        />
        <meta
          property="og:url"
          content="https://www.dkzstudio.com/#/news/DKZ-studio-brightness-in-the-fortress-sound-2024"
        />
      </Helmet>
      <NavbarReactV2EN />
      <div className="d-none d-lg-block">
        <Novedades9DetallesEscEN />
      </div>
      <div className="d-lg-none d-block">
        <Novedades9DetallesCelEN />
      </div>
      <div className="color-negro-fondo">
        <DosBotonesHomeEN />
      </div>
      <FooterReactEN />
    </div>
  );
}
