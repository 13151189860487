import { Image } from "react-bootstrap";
import NovedadesLista5 from "./NovedadesLista5";
import NovedadesLista7 from "./NovedadesLista7";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo:
    "DKZ studio Inaugura el Ciclo de Entrevistas “Persiguiendo tus Sueños” con Gaston Ugarte de Pixar",
  subtitulo:
    "Estamos emocionados de anunciar el lanzamiento de nuestro nuevo ciclo de entrevistas, “Persiguiendo tus Sueños”.",
  descripcion:
    "En esta primera entrega, contamos con la participación especial de Gaston Ugarte, un talentoso argentino con más de 17 años de experiencia en Pixar. Gaston ha trabajado en películas emblemáticas como Wall-E, Up, Coco, Valiente y Mi buen dinosaurio, y actualmente se desempeña como Supervisor de Set en el prestigioso estudio de animación.",
  descripcion1b:
    "Durante la entrevista, Gaston nos lleva a través de su inspiradora trayectoria desde sus humildes comienzos hasta sus contribuciones más destacadas en la industria cinematográfica. Conoceremos los desafíos y éxitos que ha encontrado en su camino, ofreciendo una visión única de lo que significa trabajar en una de las compañías más influyentes del mundo del entretenimiento.",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "Este ciclo de entrevistas contará con diversas colaboraciones de colegas en el rubro publicitario. Para esta ocasión especial, hemos trabajado en conjunto con Vero Jamet Garrido, de Cucu Comunicación, cuya experiencia y talento han enriquecido nuestro proyecto.",
  descripcion2:
    " No te pierdas la entrevista completa, que estará disponible muy pronto en nuestro canal de YouTube. ¡Asegúrate de suscribirte para no perderte este fascinante recorrido por la carrera de un verdadero apasionado del cine y la animación!",
  descripcion2b: "DKZ Studio. 2024. Todos los derechos reservados.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
};

export default function Novedades8DetEscritorio() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="color-negro-fondo">
      <div className="mt-5 container d-flex ">
        <div className="col-8">
          <h2 className="text-white peso-bold mx-5 px-5 py-2">{titulo}</h2>
          <h5 className="text-white mx-5 px-5">{subtitulo}</h5>
          <br />
          <Image
            className="w-100"
            src={imagen1}
            alt="imagen de la noticia"
            fluid
          />
          <br />
          <div className="d-flex">
            <h4 className="text-white mt-3 mb-0">
              {textoImagen1}
              <hr className="text-white px-5" />
            </h4>
          </div>
          {/* <h6 className="text-white my-4 py-2 mx-5 px-5">{fecha}</h6> */}
          <h5 className="text-white mt-4 wrap-pre mx-5 px-5">
            {descripcion} <br />
            {descripcion1b} <br />
            {descripcion1c} <br />
            {descripcion1d}
          </h5>
          {imagen2 === "" ? (
            ""
          ) : (
            <div>
              <Image
                className="w-100 mt-5 mb-4"
                src={imagen2}
                alt="imagen de la noticia"
                fluid
              />
            </div>
          )}
          {tituloIntermedio === "" ? (
            ""
          ) : (
            <div>
              <h4 className="text-white text-center mx-4 my-5 peso-bold">
                {tituloIntermedio}
              </h4>
            </div>
          )}
          <div className="pb-5">
            <h5 className="text-white mt-4 wrap-pre mx-5 px-5 mb-5">
              {descripcion2} <br />
              {descripcion2b}
            </h5>
          </div>
        </div>
        <div className="col-4 ms-4">
          <hr className="text-white" />
          <h4 className="text-white tamaño-mas-grande peso-bold">
            Todos los Artículos:
          </h4>
          <div>
            <NovedadesLista7 />
          </div>
        </div>
      </div>
    </div>
  );
}
