import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useParams } from "react-router";
import { Button, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo:
    "DKZ studio is positioned as a leader in Digital Marketing in Spain in just 9 months.",
  subtitulo:
    "In a world where online presence is critical to business success, DKZ studio continues to stand out with its innovative approach and dedication to digital marketing. With the arrival of 2024, DKZ reaffirms its position as a pioneer in the field.",
  descripcion:
    "“2024 is marking a significant milestone as we have experienced an unprecedented expansion in Spain,” said Guillermo Sojo, the agency's current CEO. In just 9 months since its arrival on the European continent, the company has achieved an impressive positioning thanks to a visionary and collective leadership by several members, collaborators and partners.",
  descripcion1b:
    "“We are delighted to have achieved such remarkable success in such a short time,”commented Guillermo Sojo Bollini. ”Our focus on delivering effective solutions within the area of cutting-edge digital marketing and our commitment to excellence have been instrumental to this achievement.”",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "Integral to this expansion has been DKZ Studio's focus on social media. Recognizing the power and influence of platforms such as Instagram, Facebook and Tiktok, DKZ has strengthened its social media department, highlighting its great potential and professionalism in creating and executing effective strategies.",
  descripcion2:
    "“Social networks are a crucial element in any company's digital marketing strategy today,” explained Ignacio Pintos, director of DKZ's social media department. “We take pride in offering our clients creative and dynamic campaigns that generate tangible results.”",
  descripcion2b:
    "With its focus on innovation, creativity and excellence in customer service, DKZ studio continues to consolidate its position as a leader in Spain. With expansion into other countries, such as Italy, Portugal and England, as a testament to its success, the company expects to continue to exceed expectations and set new standards in the global marketplace.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1712084338/PARA%20LAS%20NOTICIAS/dkznews_jgh7lr.jpg",
};

export default function Novedades7DetallesCelEN() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  return (
    <div className="mt-0 container">
      <div className="mx-3 pb-5">
        <h3 className="text-white peso-bold">{titulo}</h3>
        <h5 className="text-white mt-4 ">{subtitulo}</h5>

        <br />
        <Image className="w-100" src={imagen1} alt="" fluid />
        <br />
        <h5 className="text-white mt-1 ms-3">{textoImagen1}</h5>

        <h6 className="text-white my-4 py-2">{fecha} </h6>

        <h5 className="text-white  mt-4 wrap-pre">
          {" "}
          {descripcion} <br />
          {descripcion1b} <br />
          {descripcion1c} <br />
          {descripcion1d}
        </h5>
        {tituloIntermedio === "" ? (
          ""
        ) : (
          <h5 className="text-white  mx-4 my-5 peso-bold">
            {tituloIntermedio}
          </h5>
        )}

        <h5 className="text-white mt-4 wrap-pre">
          {descripcion2} <br />
          {descripcion2b}
        </h5>
      </div>
      <div className="d-flex justify-content-center pt-4">
        <Link className="text-decoration-none text-white" to="/news">
          <Button as={Link} to="/news" className="boton-naranja-grande">
            <h5 className="peso-bold ps-4 d-lg-none d-block">MORE NEWS</h5>
            <h3 className="peso-bold pe-3 d-lg-none d-block">➝</h3>
          </Button>
        </Link>
      </div>
    </div>
  );
}
