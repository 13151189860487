import { Image } from "react-bootstrap";
import NovedadesLista7EN from "./NovedadesLista7EN";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo:
    "DKZ studio Inaugurates “Chasing Your Dreams” Interview Series with Pixar's Gaston Ugarte",
  subtitulo:
    " We are excited to announce the launch of our new interview series, “Chasing Your Dreams”. In this first installment, we have the special participation of Gaston Ugarte, a talented Argentinean with over 17 years of experience at Pixar. Gaston has worked on iconic films such as Wall-E, Up, Coco, Valiente and My Good Dinosaur, and currently serves as Set Supervisor at the prestigious animation studio.",
  descripcion:
    "During the interview, Gaston takes us through his inspiring journey from his humble beginnings to his most outstanding contributions to the film industry. ",
  descripcion1b:
    "We will learn about the challenges and successes he has encountered along the way, offering a unique insight into what it means to work for one of the most influential companies in the world of entertainment.",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "This series of interviews will feature several collaborations with colleagues in the advertising industry. For this special occasion, we have worked together with Vero Jamet Garrido, from Cucu Comunicación, whose experience and talent have enriched our project.",
  descripcion2:
    "Don't miss the full interview, which will be available very soon on our YouTube channel, so be sure to subscribe so you don't miss this fascinating journey through the career of a true film and animation enthusiast!",
  descripcion2b: "DKZ Studio. 2024. All rights reserved.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
};

export default function Novedades8DetallesEscEN() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="color-negro-fondo">
      <div className="mt-5 container d-flex ">
        <div className="col-8">
          <h2 className="text-white peso-bold mx-5 px-5 py-2">{titulo}</h2>
          <h5 className="text-white mx-5 px-5">{subtitulo}</h5>
          <br />
          <Image
            className="w-100"
            src={imagen1}
            alt="imagen de la noticia"
            fluid
          />
          <br />
          <div className="d-flex">
            <h4 className="text-white mt-3 mb-0">
              {textoImagen1}
              <hr className="text-white px-5" />
            </h4>
          </div>
          {/* <h6 className="text-white my-4 py-2 mx-5 px-5">{fecha}</h6> */}
          <h5 className="text-white mt-4 wrap-pre mx-5 px-5">
            {descripcion} <br />
            {descripcion1b} <br />
            {descripcion1c} <br />
            {descripcion1d}
          </h5>
          {imagen2 === "" ? (
            ""
          ) : (
            <div>
              <Image
                className="w-100 mt-5 mb-4"
                src={imagen2}
                alt="imagen de la noticia"
                fluid
              />
            </div>
          )}
          {tituloIntermedio === "" ? (
            ""
          ) : (
            <div>
              <h4 className="text-white text-center mx-4 my-5 peso-bold">
                {tituloIntermedio}
              </h4>
            </div>
          )}
          <div className="pb-5">
            <h5 className="text-white mt-4 wrap-pre mx-5 px-5 mb-5">
              {descripcion2} <br />
              {descripcion2b}
            </h5>
          </div>
        </div>
        <div className="col-4 ms-4">
          <hr className="text-white" />
          <h4 className="text-white tamaño-mas-grande peso-bold">
            All Articles:
          </h4>
          <div>
            <NovedadesLista7EN />
          </div>
        </div>
      </div>
    </div>
  );
}
