import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useParams } from "react-router";
import { Button, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo: "DKZ studio Brillo at Fortaleza Sound 2024",
  subtitulo:
    "DKZ stood out in the prestigious musical event with a first class audiovisual production, capturing every detail of the live performances of renowned Spanish artists.",
  descripcion:
    "At the end of June and first of July 2024, DKZ studio had the privilege of participating in the Fortaleza Sound, one of the most anticipated musical events of the year. In this edition, DKZ was in charge of capturing every magical moment with a high quality production, using more than three live cameras to ensure that no detail of the performances of outstanding artists was lost.",
  descripcion1b:
    "DKZ implemented a live editing island, which allowed for real-time uploads, offering fans an unparalleled experience. This innovative and dynamic approach allowed the event's fans to enjoy the performances almost instantly, adding an unprecedented level of interaction and satisfaction.",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "The event featured the participation of renowned artists from the Spanish music scene, such as Vetusta Morla, Lori Meyers, Rayden, Cariño, León Benavente, among others. Working with these talented musicians was a real pleasure and a testament to DKZ's commitment to excellence. ",
  descripcion2:
    "DKZ's participation in the Fortaleza Sound 2024 once again demonstrated its ability to take audiovisual production to new levels, combining advanced technology with a highly committed team. This achievement is a sign of the growth and dedication of the agency to always offer the best in their projects.",
  descripcion2b:
    "DKZ Studio thanks everyone who was part of this unforgettable musical adventure and looks forward to continuing to provide exceptional experiences in future events.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg",
};
export default function Novedades9DetallesCelEN() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  return (
    <div className="mt-0 container">
      <div className="mx-3 pb-5">
        <h3 className="text-white peso-bold">{titulo}</h3>
        <h5 className="text-white mt-4 ">{subtitulo}</h5>

        <br />
        <Image className="w-100" src={imagen1} alt="" fluid />
        <br />
        <h5 className="text-white mt-1 ms-3">{textoImagen1}</h5>

        <h6 className="text-white my-4 py-2">{fecha} </h6>

        <h5 className="text-white  mt-4 wrap-pre">
          {" "}
          {descripcion} <br />
          {descripcion1b} <br />
          {descripcion1c} <br />
          {descripcion1d}
        </h5>
        {tituloIntermedio === "" ? (
          ""
        ) : (
          <h5 className="text-white  mx-4 my-5 peso-bold">
            {tituloIntermedio}
          </h5>
        )}

        <h5 className="text-white mt-4 wrap-pre">
          {descripcion2} <br />
          {descripcion2b}
        </h5>
      </div>
      <div className="d-flex justify-content-center pt-4">
        <Link className="text-decoration-none text-white" to="/news">
          <Button as={Link} to="/news" className="boton-naranja-grande">
            <h5 className="peso-bold ps-4 d-lg-none d-block">MORE NEWS</h5>
            <h3 className="peso-bold pe-3 d-lg-none d-block">➝</h3>
          </Button>
        </Link>
      </div>
    </div>
  );
}
