import { Image } from "react-bootstrap";
import NovedadesLista5 from "./NovedadesLista5";
import NovedadesLista7 from "./NovedadesLista7";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo: "DKZ studio Brillo en el Fortaleza Sound 2024",
  subtitulo:
    "DKZ se destaco en el prestigioso evento musical con una producción audiovisual de primer nivel, capturando cada detalle de las actuaciones en vivo de reconocidos artistas españoles.",
  descripcion:
    "Los días finales de junio y primero de julio de 2024, DKZ studio tuvo el privilegio de participar en el Fortaleza Sound, uno de los eventos musicales más esperados del año. En esta edición, DKZ se encargó de capturar cada momento mágico con una producción de alta calidad, utilizando más de tres cámaras en vivo para asegurar que no se perdiera ningún detalle de las actuaciones de destacados artistas.",
  descripcion1b:
    "DKZ implementó una isla de edición en vivo, lo que permitió subir los trabajos en tiempo real, ofreciendo a los fans una experiencia sin igual. Este enfoque innovador y dinámico permitió que los seguidores del evento pudieran disfrutar de las actuaciones casi al instante, añadiendo un nivel de interacción y satisfacción sin precedentes.",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "El evento contó con la participación de artistas de renombre de la escena musical española, tales como Vetusta Morla, Lori Meyers, Rayden, Cariño, León Benavente, entre otros. Trabajar con estos talentosos músicos fue un verdadero placer y un testimonio del compromiso de DKZ con la excelencia. ",
  descripcion2:
    " La participación de DKZ en el Fortaleza Sound 2024 demostró una vez más su capacidad para llevar la producción audiovisual a nuevos niveles, combinando tecnología avanzada con un equipo altamente comprometido. Este logro es una muestra del crecimiento y la dedicación de la agencia para ofrecer siempre lo mejor en sus proyectos.",
  descripcion2b:
    "DKZ Studio agradece a todos los que formaron parte de esta inolvidable aventura musical y espera continuar brindando experiencias excepcionales en futuros eventos.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg",
};

export default function Novedades9DetEscritorio() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="color-negro-fondo">
      <div className="mt-5 container d-flex ">
        <div className="col-8">
          <h2 className="text-white peso-bold mx-5 px-5 py-2">{titulo}</h2>
          <h5 className="text-white mx-5 px-5">{subtitulo}</h5>
          <br />
          <Image
            className="w-100"
            src={imagen1}
            alt="imagen de la noticia"
            fluid
          />
          <br />
          <div className="d-flex">
            <h4 className="text-white mt-3 mb-0">
              {textoImagen1}
              <hr className="text-white px-5" />
            </h4>
          </div>
          {/* <h6 className="text-white my-4 py-2 mx-5 px-5">{fecha}</h6> */}
          <h5 className="text-white mt-4 wrap-pre mx-5 px-5">
            {descripcion} <br />
            {descripcion1b} <br />
            {descripcion1c} <br />
            {descripcion1d}
          </h5>
          {imagen2 === "" ? (
            ""
          ) : (
            <div>
              <Image
                className="w-100 mt-5 mb-4"
                src={imagen2}
                alt="imagen de la noticia"
                fluid
              />
            </div>
          )}
          {tituloIntermedio === "" ? (
            ""
          ) : (
            <div>
              <h4 className="text-white text-center mx-4 my-5 peso-bold">
                {tituloIntermedio}
              </h4>
            </div>
          )}
          <div className="pb-5">
            <h5 className="text-white mt-4 wrap-pre mx-5 px-5 mb-5">
              {descripcion2} <br />
              {descripcion2b}
            </h5>
          </div>
        </div>
        <div className="col-4 ms-4">
          <hr className="text-white" />
          <h4 className="text-white tamaño-mas-grande peso-bold">
            Todos los Artículos:
          </h4>
          <div>
            <NovedadesLista7 />
          </div>
        </div>
      </div>
    </div>
  );
}
