import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useParams } from "react-router";
import { Button, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const noticia1 = {
  id: 1,
  seccion: "DKZ",
  titulo:
    "DKZ studio Inaugurates “Chasing Your Dreams” Interview Series with Pixar's Gaston Ugarte",
  subtitulo:
    " We are excited to announce the launch of our new interview series, “Chasing Your Dreams”. In this first installment, we have the special participation of Gaston Ugarte, a talented Argentinean with over 17 years of experience at Pixar. Gaston has worked on iconic films such as Wall-E, Up, Coco, Valiente and My Good Dinosaur, and currently serves as Set Supervisor at the prestigious animation studio.",
  descripcion:
    "During the interview, Gaston takes us through his inspiring journey from his humble beginnings to his most outstanding contributions to the film industry. ",
  descripcion1b:
    "We will learn about the challenges and successes he has encountered along the way, offering a unique insight into what it means to work for one of the most influential companies in the world of entertainment.",
  descripcion1c: "",
  descripcion1d: "",
  tituloIntermedio:
    "This series of interviews will feature several collaborations with colleagues in the advertising industry. For this special occasion, we have worked together with Vero Jamet Garrido, from Cucu Comunicación, whose experience and talent have enriched our project.",
  descripcion2:
    "Don't miss the full interview, which will be available very soon on our YouTube channel, so be sure to subscribe so you don't miss this fascinating journey through the career of a true film and animation enthusiast!",
  descripcion2b: "DKZ Studio. 2024. All rights reserved.",
  imagen1:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
  imagen2: "",
  imagen3: "",
  fecha: "",
  textoImagen1: "",
  textoImagen2: "",
  textoImagen3: "",
  imagenMini:
    "https://res.cloudinary.com/dlzcbrqax/image/upload/v1716431562/foto%20noticias%20cuadro/noticia8_yjzoy0.png",
};

export default function Novedades8DetallesCelEN() {
  const {
    id,
    titulo,
    subtitulo,
    descripcion,
    descripcion1b,
    descripcion1c,
    descripcion1d,
    tituloIntermedio,
    descripcion2,
    descripcion2b,
    imagen1,
    imagen2,
    imagen3,
    fecha,
    textoImagen1,
    textoImagen2,
    textoImagen3,
    imagenMini,
  } = noticia1;

  return (
    <div className="mt-0 container">
      <div className="mx-3 pb-5">
        <h3 className="text-white peso-bold">{titulo}</h3>
        <h5 className="text-white mt-4 ">{subtitulo}</h5>

        <br />
        <Image className="w-100" src={imagen1} alt="" fluid />
        <br />
        <h5 className="text-white mt-1 ms-3">{textoImagen1}</h5>

        <h6 className="text-white my-4 py-2">{fecha} </h6>

        <h5 className="text-white  mt-4 wrap-pre">
          {" "}
          {descripcion} <br />
          {descripcion1b} <br />
          {descripcion1c} <br />
          {descripcion1d}
        </h5>
        {tituloIntermedio === "" ? (
          ""
        ) : (
          <h5 className="text-white  mx-4 my-5 peso-bold">
            {tituloIntermedio}
          </h5>
        )}

        <h5 className="text-white mt-4 wrap-pre">
          {descripcion2} <br />
          {descripcion2b}
        </h5>
      </div>
      <div className="d-flex justify-content-center pt-4">
        <Link className="text-decoration-none text-white" to="/news">
          <Button as={Link} to="/news" className="boton-naranja-grande">
            <h5 className="peso-bold ps-4 d-lg-none d-block">MORE NEWS</h5>
            <h3 className="peso-bold pe-3 d-lg-none d-block">➝</h3>
          </Button>
        </Link>
      </div>
    </div>
  );
}
