import { FooterReact } from "../../components/FooterReact/FooterReact";
import DosBotonesSC from "../../components/dosBotonesSC/DosBotonesSC";
import { NavbarReactV2 } from "../../components/navbarReactV2/NavbarReactV2";
import { Helmet } from "react-helmet-async";
import Novedades9DetEscritorio from "../../components/NovedadesHomeNwe/Detalle/Novedades9DetEscritorio";
import Novedades9DetCel from "../../components/NovedadesHomeNwe/Detalle/Novedades9DetCel";

export default function Noticias9() {
  return (
    <div className="color-negro-fondo">
      <Helmet>
        <title>DKZ studio Brillo en el Fortaleza Sound 2024</title>
        <meta
          property="og:title"
          content="DKZ studio Brillo en el Fortaleza Sound 2024"
        />
        <meta
          name="description"
          content="DKZ se destaco en el prestigioso evento musical con una producción audiovisual de primer nivel, capturando cada detalle de las actuaciones en vivo de reconocidos artistas españoles."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dlzcbrqax/image/upload/v1722210003/Eventos/eventos%20esp/fiesta%20audiovisual/92ce20dd-5f72-41f0-8cd0-d3819606a071_fxhy2p.jpg"
        />
        <meta
          property="og:url"
          content="https://www.dkzstudio.com/#/noticia/DKZ-studio-brillo-en-el-fortaleza-sound-2024"
        />
      </Helmet>
      <NavbarReactV2 />
      <div className="d-none d-lg-block">
        <Novedades9DetEscritorio />
      </div>
      <div className="d-lg-none d-block">
        <Novedades9DetCel />
      </div>
      <div className="color-negro-fondo">
        <DosBotonesSC />
      </div>
      <FooterReact />
    </div>
  );
}
