import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./clientes.css";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Image } from "react-bootstrap";
import LineaNaranja from "../../LineaNaranja";

import fortaleza from "../../../assets/logos/fortaleza.png";
import santa from "../../../assets/logos/santaAna.png";
import hola from "../../../assets/logos/Hola.png";
import dkv from "../../../assets/logos/DKV.png";
import wereal250 from "../../../assets/logos/wereal250.png";

import abanco250 from "../../../assets/logos/abancos250.png";
import cartagena250 from "../../../assets/logos/cartagena250.png";
import polo250 from "../../../assets/logos/polo250.png";
import roma250 from "../../../assets/logos/roma250.png";

import dilema250 from "../../../assets/logos/dilema250.png";
import mondelez250 from "../../../assets/logos/mondelez250.png";
import cp250 from "../../../assets/logos/cajapopular250.png";
import sancor250 from "../../../assets/logos/sancor250.png";

export const ClientesCelu = () => {
  return (
    <div>
      <div className="d-md-none d-block ">
        <div className="container ">
          <div className="mx-3">
            <LineaNaranja />
            <div>
              <h3 className="peso-bold py-4">CLIENTES</h3>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={10}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper bg-white"
      >
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={fortaleza}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={santa}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={dkv}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={hola}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={wereal250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={abanco250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={cartagena250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>

        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={polo250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={roma250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={dilema250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>

        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={mondelez250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>

        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={cp250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
        <SwiperSlide className="cartas-slider">
          <Image
            className="mx-5 py-4 icono-carousel-clientes"
            src={sancor250}
            alt="caja popular"
            fluid
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
