import { FooterReact } from "../../components/FooterReact/FooterReact";
import DosBotonesSC from "../../components/dosBotonesSC/DosBotonesSC";
import { NavbarReactV2 } from "../../components/navbarReactV2/NavbarReactV2";
import Novedades8DetEscritorio from "../../components/NovedadesHomeNwe/Detalle/Novedades8DetEscritorio";
import Novedades8DetCel from "../../components/NovedadesHomeNwe/Detalle/Novedades8DetCel";
import { Helmet } from "react-helmet-async";

export default function Noticias8() {
  return (
    <div className="color-negro-fondo">
      <NavbarReactV2 />
      <div className="d-none d-lg-block">
        <Novedades8DetEscritorio />
      </div>
      <div className="d-lg-none d-block">
        <Novedades8DetCel />
      </div>
      <div className="color-negro-fondo">
        <DosBotonesSC />
      </div>
      <FooterReact />
    </div>
  );
}
